<template>
  <div class="">
    <el-card class="box-card">
      <div class="from-box">
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          label-width="130px"
          class="demo-ruleForm from-box"
        >
          <el-form-item
            label="姓名："
            prop="realName"
            :rules="[{ required: true, message: '请输入姓名' }]"
          >
            <el-input
              v-model="ruleForm.realName"
              :disabled="userInfo.idStatus == 0 ? false : true"
              placeholder="请输入真实姓名"
              style="width: 500px"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="身份证号："
            prop="idNumber"
            :rules="[{ required: true, message: '请输入身份证号' }]"
          >
            <el-input
              v-model="ruleForm.idNumber"
              :disabled="userInfo.idStatus == 0 ? false : true"
              placeholder="请输入身份证号"
              style="width: 500px"
            ></el-input>
            <div class="tishi font14" v-if="userInfo.idStatus == 0">
              *请确认个人信息，验证后不可更改
            </div>
          </el-form-item>
          <!-- v-if="userInfo.idStatus == -1" -->
          <el-form-item v-if="userInfo.idStatus != 1">
            <el-button
              class="btn"
              type="primary"
              @click="submitForm('ruleForm')"
              >确定</el-button
            >
          </el-form-item>
          <div class="shiming" v-else>
            <img src="../../assets/images/mycenter/yishiming.png" />
            <div class="font16">
              {{
                userInfo.idStatus == 0
                  ? "实名认证请求正在审核中"
                  : "您的实名认证已完成！"
              }}
            </div>
          </div>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        realName: "",
        idNumber: "",
      },
      userInfo: {},
    };
  },
  created() {
    this.getUserInfo();
  },
  mounted() {},
  methods: {
    getUserInfo() {
      this.$api.getUserInfo("get").then((res) => {
        this.userInfo = res.data.user;
        this.ruleForm.realName = res.data.userAuthentication.realName;
        this.ruleForm.idNumber = res.data.userAuthentication.idNumber;
        // console.log("RealName 实名认证返回， 更新用户信息缓存", res);
        window.sessionStorage.setItem("userInfo", JSON.stringify(res.data.user));
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$api.realName("post", this.ruleForm).then((res) => {
            this.getUserInfo();
            this.$message({
              message: res.code == 10200 ? "实名认证通过" : res.message,
              type: res.code == 10200 ? "success" : "error",
            });
            setTimeout(() => {
              window.location.reload();
            }, 15000);
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-card {
  border-radius: 0px;
  border: none;
  box-shadow: 0px 4px 13px 0px rgb(0 0 0 / 3%) !important;
}
/deep/.el-card__body {
  padding: 75px 30px 100px 30px;
}

.box-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .from-box {
    /deep/ .el-input__inner {
      border-radius: 0px;
    }
    /deep/ .el-button {
      border-radius: 0px;
      width: 100%;
      margin-top: 92px;
      background: #00bcff;
      border: none;
    }
    .tishi {
      padding-left: 16px;
      color: #ff4444;
    }
    .shiming {
      text-align: center;
      margin-top: 80px;
      img {
        width: 83px;
        height: 83px;
      }
      div {
        color: #999999;
      }
    }
  }
}
</style>
